import * as React from "react"
import {useState} from "react"
import "../assets/styles/index.scss"
import Calendar from "../components/Calendar/Calendar"
import Background from "../components/Background/Background"
import Nav from "../components/Nav/Nav"
import Footer from "../components/Footer/Footer"
import {Helmet} from "react-helmet"
import {Link as ScrollLink} from 'react-scroll'
import toast, { Toaster } from 'react-hot-toast';


import islandHeroImg from "../assets/images/graphics/island.png"
import planetHeroImg from "../assets/images/graphics/planet.png"
import sensesImg from "../assets/images/graphics/senses.png"
import projectImg from "../assets/images/graphics/project.png"
import expeditionImg from "../assets/images/graphics/expedition.png"
import nightfallImg from "../assets/images/graphics/nightfall.png"
import moonHeroImg from "../assets/images/graphics/moon.png"
import mainHeroImg from "../assets/images/graphics/mainHero.jpg"
import shareImg from "../assets/images/meta/share.png"
import ticketsImg from "../assets/images/graphics/tickets.png"
import nocturneXImg from "../assets/images/branding/nocturne-x.svg"
import heroVideo from "../assets/video/hero.mp4"
import blackFridayImg from "../assets/images/graphics/blackfriday.png"
import {Link} from "gatsby"

const IndexPage = ({location}) => {
    const [scrollLock, setScrollLock] = useState(false)

    return (
        <main className="Wrapper">
            <Helmet
                bodyAttributes={{
                    class: scrollLock ? "ScrollLock" : ""
                }}
            >
                <meta charSet="utf-8"/>
                <title>Nocturne X</title>
                <meta property="og:image" content={`https://nocturne-x.com${shareImg}`}/>
                <meta property="og:title" content="Nocturne X - Get your ticket now"/>
                <meta property="og:description" content="Don’t miss this transformative multisensory art experience"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="nocturnexsf"/>
                <meta name="twitter:image" content={`https://nocturne-x.com${shareImg}`}/>
                <meta name="twitter:title" content="Nocturne X - Get your ticket now"/>
                <meta name="twitter:description" content="Don’t miss this transformative multisensory art experience"/>
                <link rel="canonical" href="https://nocturne-x.com"/>
                <meta name="facebook-domain-verification" content="0u83874sh6kcurzqou47ey10jl55i3" />
            </Helmet>

            <div className="Overlay"/>

            <Background/>

            <Nav />

            <div className="Hero">
                <div className="Hero__container">
                    <video
                            type="video/mp4"
                            muted
                            autoPlay
                            loop
                            playsInline
                            src={heroVideo}
                            style={{"width":"100%"}}
                            className="Hero__video"
                        />
                </div>

                {/* <img
                    src={mainHeroImg}
                    alt="Nocturne X"
                    className="Hero__graphic"
                />*/}
                <img 
                    src={nocturneXImg}
                    alt="Nocturne X Logo"
                    className="Img__wordmark"
                />
                <p className="Hero__subtitle Text--emphasis Text--red">
                    A transformative multisensory art experience open now in San Francisco
                </p>
            </div>

            <div className="Section" id="explore">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-12 col-lg-6 order-first">
                            <h2 className="Text--preheading Text--yellow">
                                Awaken Your
                            </h2>
                            <h1 className="Text--heading Text--yellow">
                                Senses
                            </h1>
                            <p className="Text--section">
                                Enter an otherworldly forest of sound and light on planet Nocturne X. In this <span
                                className="Text--emphasis">large-scale interactive playground</span>, you will encounter a rich environment of extraterrestrial life that reacts to your every touch
                                and movement.
                            </p>
                        </div>

                        <div className="col col-12 col-lg-6 order-last">
                            <img
                                src={sensesImg}
                                alt="Glowing plant photo."
                                className="w-100 p-3"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="Section" id="explore">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-12 col-lg-6 order-first order-lg-last">
                            <h2 className="Text--preheading Text--red">
                                About the
                            </h2>
                            <h1 className="Text--heading Text--red">
                                Project
                            </h1>
                            <p className="Text--section">
                                Created by <span
                                className="Text--emphasis">Numina Studio</span>, a team of over 100 local Bay Area
                                artists
                                and creative technologists, incubated at <span
                                className="Text--emphasis">Gray Area</span> in San Francisco, this expansive
                                exhibition is home to hundreds of bioluminescent sculptures and unique <span
                                className="Text--emphasis">interactive installations at the cutting edge of technology</span>.
                                Come with curiosity to explore, discover and unravel the unforgettable story of Nocturne
                                X's
                                rich biosphere.
                            </p>
                        </div>

                        <div className="col col-12 col-lg-6 order-last order-lg-first">
                            <img
                                src={projectImg}
                                alt="Console with glowing plant."
                                className="w-100 p-3"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="Section" id="tickets">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-12 col-lg-6 order-first">
                            <img
                                src={ticketsImg}
                                alt="Tickets artwork."
                                className="w-25 mb-4"
                            />
                            <h2 className="Text--preheading Text--teal">
                                Get your
                            </h2>
                            <h1 className="Text--heading Text--teal">
                                Ticket
                            </h1>
                            <p className="Text--section">
                                Tickets are now available for our most intrepid explorers! Book now and receive behind-the-scenes previews and exclusive transmissions from Nocturne X.
                            </p>
                            <p className="Text--tight">
                                <span className="Text--emphasis">Gray Area</span><br/>
                                2665 Mission St.<br/>
                                San Francisco, CA 94110
                            </p>
                            <p className="Text--subdued">
                                * Children aged 4 and under enjoy free admission. For group rates and bookings, and other 
                                questions about Nocturne X, contact us at <a className="Link--teal" href="mailto:info@nocturne-x.com">info@nocturne-x.com</a>
                            </p>
                            <p className="Text--subdued">
                                * To ensure the safety of our community and in accordance with San Francisco city guidelines, Gray Area requires all visitors aged 12 or older to provide proof of vaccination. <a
                                className="Link--teal"
                                href="https://grayarea.org/visitor-guidelines/">Read More</a>
                            </p>
                        </div>

                        <div className="col col-12 col-lg-6 order-last">
                            <p className="Text--emphasis">
                                Click on a date in the calendar below to book your arrival window.
                            </p>
                            <Calendar setScrollLock={setScrollLock} month={1}/>
                        </div>
                    </div>
                </div>
            </div>


            <div className="Section" id="nightfall">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-12 col-lg-6 order-first order-lg-last">
                            <h2 className="Text--heading Text--red">
                                Nightfall
                            </h2>
                            <h1 className="Text--preheading Text--red">
                                On Nocturne X
                            </h1>
                            <p className="Text--section">
                                Come see Nocturne X in a completely different light with <span
                                className="Text--emphasis">Nightfall</span>! Starting <span
                                className="Text--emphasis">October 29</span>, dance the night away on a journey
                                through space-time with our weekend night programming just for adults. Come see
                                this expansive exhibition light up with live DJ performances, immersive theater
                                experiences and more. Grab a drink and reunite with friends under Nocturne X's
                                eight moons and immerse yourself in something new.
                            </p>
                            <Link
                                to="/nightfall"
                                className="Button Button--red"
                            >
                                See more
                            </Link>
                        </div>

                        <div className="col col-12 col-lg-6 order-last order-lg-first">
                            <img
                                src={nightfallImg}
                                alt="Nightfall at Nocturne X"
                                className="w-100 p-3"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="Section" id="explore">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-12 col-lg-6">
                            <h2 className="Text--preheading Text--yellow">
                                Private
                            </h2>
                            <h1 className="Text--heading Text--yellow">
                                Expedition
                            </h1>
                            <p className="Text--section">
                                Create an unforgettable evening for your friends, co-workers, and classmates on Nocturne
                                X.
                                The 4,000 sq ft transformed interplanetary venue located at Gray Area is now <span
                                className="Text--emphasis">accepting rental reservations from October—December 2021.</span> With
                                an expansive dance floor, DJ booth, and unparalleled music visualization through
                                large-scale
                                sound-reactive light installations, let Nocturne X help to bring your event to new
                                stratospheres.
                            </p>
                            <a
                                className="Button"
                                href="https://airtable.com/shrgVGUvBEYaNgo0k"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Get in Touch
                            </a>
                        </div>

                        <div className="col col-12 col-lg-6 order-last">
                            <img
                                src={expeditionImg}
                                alt="Glowing plant photo."
                                className="w-100 p-3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        <Footer />

        <Toaster 
                            position="top-center"
                            containerStyle={{
                                top: "50%"
                            }}
                            toastOptions={{
                                className: '',
                                duration: 5000,
                                style: {
                                  background: '#363636',
                                  color: '#fff',
                                }
                            }}
                        />

        </main>
    )
}

export default IndexPage

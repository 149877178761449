export const january = [
    [1, null],
    [2, 225605802027],
    [3, null],
    [4, null],
    [5, null],
    [6, 225616263317],
    [7, 225621027567],
    [8, 229135700047],
    [9, 229136372057],
    [10, null],
    [11, null],
    [12, null],
    [13, null],
    [14, null],
    [15, null],
    [16, null],
    [17, null],
    [18, null],
    [19, null],
    [20, null],
    [21, null],
    [22, null],
    [23, null],
    [24, null],
    [25, null],
    [26, null],
    [27, null],
    [28, null],
    [29, null],
    [30, null],
    [31, null],
]

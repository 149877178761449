import * as React from "react"
import {
    useEffect,
    useState
} from "react"
import EventbriteButton from 'react-eventbrite-popup-checkout'
import {october} from "./months/october"
import {november} from "./months/november"
import {december} from "./months/december"
import {january} from "./months/january"

function track_stuff(event_data) {
    if (window.fbq) window.fbq('track', 'InitiateCheckout', {'contents': event_data})
    if (window.gtag) window.gtag('event', 'begin_checkout', {
        'send_to': 'AW-882281191/2d4nCL_6n4EDEOeV2qQD',
        'item_variant': event_data
    })
}

const Calendar = ({setScrollLock, month}) => {
    //const [month, setMonth] = useState(11)
    const [showModalEvent, setShowModalEvent] = useState(null)
    const [purchaseEvent, setPurchaseEvent] = useState(null)
    const [dates, setDates] = useState([])
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        window.addEventListener("message", function (Message_Event) {
            if (Message_Event.data === "purchase") {
                if (purchaseEvent) {
                    console.log("conversion")
                    if (window.fbq) window.fbq('track', 'Purchase', {'contents': purchaseEvent})
                    if (window.gtag) window.gtag('event', 'conversion', {
                        'send_to': 'AW-882281191/psBVCJ_FpIEDEOeV2qQD',
                        'item_variant': purchaseEvent
                    })
                    if (window.gtag) window.gtag('event', 'conversion', {
                        'send_to': 'AW-325241330/jkplCLLmj-oCEPKTi5sB',
                        'item_variant': purchaseEvent
                    })
                    setPurchaseEvent(null)
                }
            }

            if (Message_Event.data.action === "close") {
                setShowModalEvent(null)
                setScrollLock(false)
            }
        })
    })

    useEffect(() => {
        switch (month) {
            case 10:
                setOffset(6)
                setDates(october)
                break
            case 11:
                setOffset(2)
                setDates(november)
                break
            case 12:
                setOffset(4)
                setDates(december)
                break
            case 1:
                setOffset(7)
                setDates(january)
            default:
                break
        }
    },[month])

    return (
        <div className="Calendar mt-lg-5">
            <div className="Calendar__months">
                {/* <button
                    className={month === 10 ? `Calendar__month selected` : `Calendar__month`}
                    onClick={() => setMonth(10)}
                >
                    October
                </button> */}
                { month === 11 && 
                <button
                    className={month === 11 ? `Calendar__month selected` : `Calendar__month`}
                >
                    November
                </button>
                }
                { month === 12 && 
                <button
                    className={month === 12 ? `Calendar__month selected` : `Calendar__month`}
                >
                    December 2021
                </button>
                }
                { month === 1 && 
                <button
                    className={month === 1 ? `Calendar__month selected` : `Calendar__month`}
                >
                    January 2022
                </button>
                }

            </div>

            <div className="Calendar__days">
                <div>Su</div>
                <div>Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div>Sa</div>
            </div>

            <div className={`Calendar__dates Calendar__dates--offset${offset}`}>
                {dates.map((date) => {
                    const day = date[0]
                    const eventID = date[1]
                    const monthPad = month.toString().padStart(2, '0')
                    const dayPad = day.toString().padStart(2, '0')

                    const dateObj = new Date()
                    const monthToday = dateObj.getMonth() + 1 // months from 1-12
                    const dayToday = dateObj.getDate()
                    const yearToday = dateObj.getFullYear()

                    const year = (month === 1) ? 2022 : 2021

                    const inThePast = (month < monthToday && year < yearToday) || (monthToday === month && day < dayToday)
                    if (!eventID || inThePast) {
                        return (
                            <a key={`${month}-${day}`} className="disabled">
                                <time dateTime={`${year}-${monthPad}-${dayPad}`}>{day}</time>
                            </a>
                        )
                    }

                    return (
                        <EventbriteButton
                            key={`${eventID}`}
                            onClick={(e) => {
                                track_stuff(`${year}-${monthPad}-${dayPad}`)
                            }}
                            ebEventId={eventID}
                        >
                            <time dateTime={`${year}-${monthPad}-${dayPad}`}>{day}</time>
                        </EventbriteButton>
                    )
                })}
            </div>

            {showModalEvent &&
            <iframe
                src={showModalEvent}
                className="Calendar__iframe"
                frameBorder="0"
            />
            }

        </div>
    )
}

export default Calendar

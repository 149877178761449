export const november = [
    [1, null],
    [2, 12668164],
    [3, 12668236],
    [4, 12668371],
    [5, 12668563],
    [6, 12668201],
    [7, 12668300],
    [8, null],
    [9, 12831525],
    [10, 12831495],
    [11, 12831456],
    [12, 12831409],
    [13, 12831380],
    [14, 12831351],
    [15, null],
    [16, null],
    [17, 203557163957],
    [18, 203558387617],
    [19, 199307011637],
    [20, 203426763927],
    [21, 203510775207],
    [22, null],
    [23, null],
    [24, null],
    [25, null],
    [26, 203511025957],
    [27, 203511467277],
    [28, 203553051657],
    [29, null],
    [30, null],
]
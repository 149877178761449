export const december = [
    [1, null],
    [2, 209619947907],
    [3, 209620248807],
    [4, 209620479497],
    [5, 209620559737],
    [6, null],
    [7, null],
    [8, null],
    [9, 209620800457],
    [10, 209620930847],
    [11, 209621071267],
    [12, 209621121417],
    [13, null],
    [14, null],
    [15, null],
    [16, null],
    [17, 209622946877],
    [18, 205322313567],
    [19, 209623097327],
    [20, null],
    [21, null],
    [22, null],
    [23, 211266121657],
    [24, null],
    [25, null],
    [26, 211381466657],
    [27, null],
    [28, null],
    [29, null],
    [30, 211384977157],
    [31, 211382630137],
]